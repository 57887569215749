<template>
  <div>
    <div>
      <p class="text-black mb-2 font-bold" :class="displayTextClasses">
        {{ displayText }}
      </p>
      <button-box
        class="mb-1"
        :content="$t(`countries.${country.code.toUpperCase()}`)"
        :prefix-flag="country.code"
        :suffix-icon="iconArrow"
        data-test="switch-country-button"
        @click.stop="handleClick"
      />
    </div>
    <switch-country
      v-if="showCountrySelector"
      :portal-name="portalName"
      :items="items"
      :selected-item="country"
      @hide="showCountrySelector = false"
    />
  </div>
</template>
<script>
import { portals } from '~/enums'
import { mutatePath } from '~/utils/mutatePath'
import { ButtonBox } from '~/components/atoms'
import { SwitchCountry } from '~/components/molecules'

export default {
  components: {
    ButtonBox,
    SwitchCountry,
  },
  props: {
    country: {
      type: Object,
      required: true,
    },
    displayText: {
      type: String,
      default: '',
    },
    redirectToCountry: {
      type: Boolean,
      default: false,
    },
    disableCountrySelector: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      default: () => [],
    },
    displayTextClasses: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      portalName: portals.PORTAL_NAME_SWITCH_COUNTRY,
      showCountrySelector: false,
      iconArrow: {
        icon: 'arrow',
      },
    }
  },
  methods: {
    handleClick() {
      this.$emit('click')
      if (this.redirectToCountry) {
        window.location.href = mutatePath(this.$route.path, {
          selectedCountry: this.country.code.toLowerCase(),
        })
      } else if (!this.disableCountrySelector) {
        this.showCountrySelector = true
      }
    },
  },
}
</script>
